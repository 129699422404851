import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../layouts/index"
import Seo from "../components/seo"

import '../styles/events.scss'
//no way this works for a seventh time
export const query = graphql`
  query eventQuery {
    allAirtableEvents(
      sort: {fields: [data___completed, data___order], order: [DESC, ASC]}
    ) {
      nodes {
        data {
          title
          type
          moreInfoLink
          zoomLink
          publishingStatus
          locationBuilding
          locationRoom
          endTime
          date
          startTime
          order
          completed
        }
      }
    }
  }
`

const EventDesktopComponent = (props) => {
    return(
      <div className={props.completed ? "eventItem completed": "eventItem"}>
          <div className="time">
              <p>{props.date}</p>
              {
                props.endTime !== "_" ? 
                <p>{props.startTime} - {props.endTime}</p> :
                <p>{props.startTime}</p>
              }
          </div>
          <p className="title">{props.title}</p>
          <div className="location">
            <p>{props.locationBuilding}</p>
            <p>{props.locationRoom}</p>
          </div>
          <div className="links">
            {
              props.moreInfoLink !== "_" ? <p className="more"><a href={props.moreInfoLink}>Instagram Post</a></p> : null
            }
            {
              props.zoomLink !== "_" ? <p className="zoom"><a href={props.zoomLink}>Zoom Link</a></p> : null
            }
          </div>
      </div>
    )
}

const EventMobileComponent = (props) => {
    return(
      <div className={props.completed ? "eventItem completed": "eventItem"}>
          <div className="eventMain">
            <h4 className="title">{props.title}</h4>
            <div className="eventDetails">
              <div className="time">
                  <p>{props.date}</p>
                  {
                    props.endTime !== "_" ? 
                    <p>{props.startTime} - {props.endTime}</p> :
                    <p>{props.startTime}</p>
                  }
              </div>
              <div className="location">
                <p>{props.locationBuilding}</p>
                <p>{props.locationRoom}</p>
              </div>
            </div>
          </div>
          <div className="links">
            {
              props.moreInfoLink !== "_" ? <a href={props.moreInfoLink}><p className="more">Instagram Post</p></a> : null
            }
            {
              props.zoomLink !== "_" ? <a href={props.zoomLink}><p className="zoom">Zoom Link</p></a> : null
            }
          </div>
      </div>
    )
}

const DesktopEvents = ({events}) => {
    return(
      <div className="events eventsDesktop">
        <div className="eventsHeader">
            <h3>Date and Time</h3>
            <h3>Event Name</h3>
            <h3>Location</h3>
            <h3>Links</h3>
        </div>
        {
          events.map(node => {
            console.log(node)
            if (node.data.publishingStatus !== "Draft"){
              return(
                <EventDesktopComponent 
                   date={node.data.date} 
                   startTime ={node.data.startTime} 
                   endTime ={node.data.endTime} 
                   title={node.data.title} 
                   type={node.data.type} 
                   locationBuilding={node.data.locationBuilding} 
                   locationRoom={node.data.locationRoom} 
                   moreInfoLink={node.data.moreInfoLink} 
                   zoomLink={node.data.zoomLink}
                   completed={node.data.completed}
                /> 
             )
            }
          })
        }
      </div>
    )
}

const MobileEvents = ({events}) => {
    return(
      <div className="events eventsMobile">
        {
          events.map(node => {
            console.log(node)
            if (node.data.publishingStatus !== "Draft"){
              return(
                <EventMobileComponent 
                   date={node.data.date} 
                   startTime={node.data.startTime} 
                   endTime={node.data.endTime} 
                   title={node.data.title} 
                   type={node.data.type} 
                   locationBuilding={node.data.locationBuilding} 
                   locationRoom={node.data.locationRoom} 
                   moreInfoLink={node.data.moreInfoLink} 
                   zoomLink={node.data.zoomLink}
                   completed={node.data.completed}/> 
             )
            }
          })
        }
      </div>
    )
}

const EventPage = (props) => {
  const {data, errors} = props

  const eventArray = data.allAirtableEvents.nodes
  console.log(eventArray)

  return(
    <>
      <Seo title="Events" />
      <DesktopEvents events={eventArray}/>
      <MobileEvents events={eventArray}/> 
    </>
  )
}

export default EventPage
